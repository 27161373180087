import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
const Pubg2 = lazy(() => import('./dynamics/defs/H1mi426632501/Kontgee'));
// const Fuz = lazy(() => import('./dynamics/defs/H1mi426632501/Hmltt'));
const Hm = lazy(() => import('./dynamics/defs/H1mi426632501/Home'));
// const Pri = lazy(() => import('./dynamics/defs/H1mi426632501/Privacy'));
// const Trm = lazy(() => import('./dynamics/defs/H1mi426632501/TermsAndConditions'));
// const Crp = lazy(() => import('./dynamics/defs/H1mi426632501/Copyright'));

function App() {
  return (
    <Router>
    <div className='app-main'>
      <Suspense>
        <Routes>
          <Route path='/' element={<Hm/>} />
          <Route path='/:username' element={<Pubg2/>} />
          {/* <Route path='/privacy-policy' element={<Pri/>} /> */}
          {/* <Route path='/terms-and-conditions' element={<Trm/>} /> */}
          {/* <Route path='/copyright' element={<Crp/>} /> */}
        </Routes>
      </Suspense>
    </div>
    </Router>
  );
}

export default App;
